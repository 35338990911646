@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
/* @define Home */
.Home {
  padding-top: 70px; }
  .Home-topSection {
    background: url(/static/media/background_top.f7113a63.png);
    width: 100%;
    color: white; }
    .Home-topSection-topImage {
      padding-top: 50px;
      width: 60%;
      height: auto;
      margin-left: auto;
      margin-right: auto; }
      .Home-topSection-topImage h1 {
        font-size: 45px; }
      .Home-topSection-topImage img {
        padding: 50px 0px 50px 0px;
        width: 100%;
        margin-left: auto;
        margin-right: auto; }
      .Home-topSection-topImage p {
        color: #d0d4d8; }
  .Home-middleSection {
    background: white;
    padding-top: 60px;
    color: #434343;
    padding-bottom: 75px; }
    .Home-middleSection-about {
      width: 60%;
      margin: auto;
      font-size: 25px;
      line-height: 30px; }
      .Home-middleSection-about-lineupBtn {
        margin: auto;
        width: 20%;
        text-align: center;
        background-color: #f5fac7;
        padding: 10px;
        border-radius: 5px; }
        .Home-middleSection-about-lineupBtn a {
          text-decoration: none;
          color: #434343;
          width: 100%; }
  .Home-moneyBorder {
    background: url(/static/media/money_border.b83ffe0e.png);
    width: 100%;
    height: 300px; }
  .Home-testimonials {
    padding-top: 100px;
    width: 80%;
    margin: auto;
    padding-bottom: 100px; }
    .Home-testimonials-caleb {
      display: table-cell; }
      .Home-testimonials-caleb-pic {
        display: table-cell;
        width: 30%; }
        .Home-testimonials-caleb-pic img {
          border: 2px black;
          height: auto;
          width: 70%; }
      .Home-testimonials-caleb-quote {
        vertical-align: middle;
        display: table-cell;
        margin-left: 50px;
        clear: both;
        width: 30%;
        font-size: 3vw;
        font-style: italic; }
        .Home-testimonials-caleb-quote .subtext {
          font-size: 2vw; }


.Settings {
  border-radius: 3px;
  margin-top: 25px; }
  .Settings-options {
    padding: 20px;
    border-radius: 3px;
    margin-top: 10px; }
    .Settings-options-header {
      background: #9c87c6;
      height: 60px; }
    .Settings-options-input {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 10px; }
      .Settings-options-input input {
        max-width: 75px; }
    .Settings-options-general {
      border-radius: 3px;
      background: white;
      padding: 20px; }
  .Settings-stacks {
    margin-top: 50px; }

.lineup-btn {
  display: inline-block;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  background-color: #E88282;
  margin-left: 15px; }

.Settings {
  border-radius: 3px;
  margin-top: 25px; }
  .Settings-options {
    padding: 20px;
    border-radius: 3px;
    margin-top: 10px; }
    .Settings-options-header {
      background: #9c87c6;
      height: 60px; }
    .Settings-options-input {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 10px; }
      .Settings-options-input input {
        max-width: 75px; }
    .Settings-options-general {
      border-radius: 3px;
      background: white;
      padding: 20px; }
  .Settings-stacks {
    margin-top: 50px; }

.lineup-btn {
  display: inline-block;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  background-color: #E88282;
  margin-left: 15px; }

.Lineups-loading {
  text-align: center;
  padding: 100px; }


.Settings {
  border-radius: 3px;
  margin-top: 25px; }
  .Settings-options {
    padding: 20px;
    border-radius: 3px;
    margin-top: 10px; }
    .Settings-options-header {
      background: #9c87c6;
      height: 60px; }
    .Settings-options-input {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 10px; }
      .Settings-options-input input {
        max-width: 75px; }
    .Settings-options-general {
      border-radius: 3px;
      background: white;
      padding: 20px; }
  .Settings-stacks {
    margin-top: 50px; }

.lineup-btn {
  display: inline-block;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  background-color: #E88282;
  margin-left: 15px; }

.Settings {
  border-radius: 3px;
  margin-top: 25px; }
  .Settings-options {
    padding: 20px;
    border-radius: 3px;
    margin-top: 10px; }
    .Settings-options-header {
      background: #9c87c6;
      height: 60px; }
    .Settings-options-input {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 10px; }
      .Settings-options-input input {
        max-width: 75px; }
    .Settings-options-general {
      border-radius: 3px;
      background: white;
      padding: 20px; }
  .Settings-stacks {
    margin-top: 50px; }

.lineup-btn {
  display: inline-block;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  background-color: #E88282;
  margin-left: 15px; }

.Lineups-loading {
  text-align: center;
  padding: 100px; }


.Settings {
  border-radius: 3px;
  margin-top: 25px; }
  .Settings-options {
    padding: 20px;
    border-radius: 3px;
    margin-top: 10px; }
    .Settings-options-header {
      background: #9c87c6;
      height: 60px; }
    .Settings-options-input {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 10px; }
      .Settings-options-input input {
        max-width: 75px; }
    .Settings-options-general {
      border-radius: 3px;
      background: white;
      padding: 20px; }
  .Settings-stacks {
    margin-top: 50px; }

.lineup-btn {
  display: inline-block;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  background-color: #E88282;
  margin-left: 15px; }

.Settings {
  border-radius: 3px;
  margin-top: 25px; }
  .Settings-options {
    padding: 20px;
    border-radius: 3px;
    margin-top: 10px; }
    .Settings-options-header {
      background: #9c87c6;
      height: 60px; }
    .Settings-options-input {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 10px; }
      .Settings-options-input input {
        max-width: 75px; }
    .Settings-options-general {
      border-radius: 3px;
      background: white;
      padding: 20px; }
  .Settings-stacks {
    margin-top: 50px; }

.lineup-btn {
  display: inline-block;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  background-color: #E88282;
  margin-left: 15px; }

.Lineups-loading {
  text-align: center;
  padding: 100px; }

.LineupGenerator {
  padding: 100px 2% 76px;
  min-height: 100vh;
  border-radius: 10px; }
  .LineupGenerator-menu {
    border-bottom: 2px solid black; }
    .LineupGenerator-menu-item {
      display: inline-block;
      margin-right: 4px;
      cursor: pointer;
      padding: 6px;
      background-color: #AF5E5E;
      color: white; }

body {
  font-family: 'Poppins', sans-serif;
}

