.Settings {
  border-radius: 3px;
  margin-top: 25px; }
  .Settings-options {
    padding: 20px;
    border-radius: 3px;
    margin-top: 10px; }
    .Settings-options-header {
      background: #9c87c6;
      height: 60px; }
    .Settings-options-input {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 10px; }
      .Settings-options-input input {
        max-width: 75px; }
    .Settings-options-general {
      border-radius: 3px;
      background: white;
      padding: 20px; }
  .Settings-stacks {
    margin-top: 50px; }

.lineup-btn {
  display: inline-block;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  color: white;
  background-color: #E88282;
  margin-left: 15px; }
