.LineupGenerator {
  padding: 100px 2% 76px;
  min-height: 100vh;
  border-radius: 10px; }
  .LineupGenerator-menu {
    border-bottom: 2px solid black; }
    .LineupGenerator-menu-item {
      display: inline-block;
      margin-right: 4px;
      cursor: pointer;
      padding: 6px;
      background-color: #AF5E5E;
      color: white; }
